<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('settings.dashboards.elements.addedit.wizardFinish')"
      :back-button-text="$t('settings.dashboards.elements.addedit.wizardPrevious')"
      :next-button-text="$t('settings.dashboards.elements.addedit.wizardNext')"
      class="mb-3"
      @on-complete="onFormSubmitted"
    >
      <!-- Element general -->
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.general')"
        :before-change="onValidationGeneral"
      >
        <validation-observer
          ref="refGeneralValidationRules"
          tag="form"
        >
          <b-row>
            <!-- TableroElementoTipoId field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.columns.elementTypeId')"
                rules="required"
              >
                <b-form-group
                  :label="$t('settings.dashboards.elements.addedit.columns.elementTypeId')"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="elementData.tableroElementoTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="item => item.id"
                    :options="elementTypes"
                    :disabled="!isNewElement"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Nombre field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('settings.dashboards.elements.addedit.columns.name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('settings.dashboards.elements.addedit.columns.name')"
                  label-for="nombre"
                >
                  <b-form-input
                    id="nombre"
                    v-model="elementData.nombre"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Nombre field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('settings.dashboards.elements.addedit.columns.description')"
                rules="required"
              >
                <b-form-group
                  :label="$t('settings.dashboards.elements.addedit.columns.description')"
                  label-for="descripcion"
                >
                  <b-form-input
                    id="descripcion"
                    v-model="elementData.descripcion"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Query Object -->
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.queryObject')"
        :before-change="onValidationQueryObject"
      >
        <b-row>
          <!-- Query object field -->
          <b-col
            cols="12"
            md="12"
          >
            <strong>
              {{ $t('settings.dashboards.elements.addedit.columns.queryObject') }}
              <b-button
                v-b-modal.add-query-object-modal
                size="sm"
                variant="success"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-middle"
                />
              </b-button>
            </strong>
            <validation-observer
              ref="refQueryObjectValidationRules"
              tag="form"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.columns.queryObject')"
                rules="required"
              >
                <b-form-group
                  class="mt-1"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="elementData.objetoConsultaId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="item => item.id"
                    :options="queryObjects"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </validation-observer>
          </b-col>
        </b-row>
      </tab-content>
      <!-- Parameters -->
      <tab-content
        v-if="parameters.length != 0"
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.parameters')"
        :before-change="onValidationParameters"
      >
        <validation-observer
          ref="refParametersValidationRules"
          tag="form"
        >
          <b-table
            ref="refParametersTable"
            :fields="parametersFields"
            :items="parameters"
          >
            <template #cell(esObligatoria)="data">
              <b-form-group>
                <b-form-checkbox
                  v-model="data.item.esObligatoria"
                />
              </b-form-group>
            </template>
            <template #cell(condicionFiltradoId)="data">
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.columns.conditionFilter')"
                rules="required"
              >
                <b-form-group>
                  <v-select
                    v-model="data.item.condicionFiltradoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="conditionFilterOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>
            <template
              #cell(etiqueta)="data"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('settings.dashboards.elements.addedit.conditionFilter.columns.label')"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="data.item.etiqueta"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>
            <template
              #cell(objetoConsultaColumnaId)="data"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.conditionFilter.columns.queryObject')"
                rules="required"
              >
                <b-form-group>
                  <v-select
                    v-model="data.item.objetoConsultaColumnaId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="item => item.id"
                    :options="columnsParameters"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>
          </b-table>
        </validation-observer>
      </tab-content>
      <!-- Condition Filter -->
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.conditionFilter')"
        :before-change="onValidationConditionFilter"
      >
        <validation-observer
          ref="refConditionFilterValidationRules"
          tag="form"
        >
          <div
            v-if="elementData.objetoConsulta && !elementData.objetoConsulta.enFuncionPaginacionPorParametro"
          >
            <b-row
              class="mb-1"
            >
              <b-col
                cols="12"
                offset-md="10"
                md="2"
              >
                <b-button
                  v-b-modal.condition-filter-add
                  block
                  variant="primary"
                >
                  {{ $t('settings.dashboards.elements.addedit.columns.addConditionFilter') }}
                </b-button>
              </b-col>
            </b-row>
            <b-table
              ref="refConditionFilterTable"
              :items="elementData.condicionesFiltrado"
              :fields="conditionFilterFields"
            >
              <template
                #cell(etiqueta)="data"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('settings.dashboards.elements.addedit.conditionFilter.columns.labels')"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="data.item.etiqueta"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>
              <template
                #cell(objetoConsultaColumnaId)="data"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('settings.dashboards.elements.addedit.conditionFilter.columns.queryObject')"
                  rules="required"
                >
                  <b-form-group>
                    <v-select
                      v-model="data.item.objetoConsultaColumnaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      label="texto"
                      :reduce="item => item.id"
                      :options="elementData.objetoConsultaColumnas"
                      transition=""
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>
              <template
                #cell(esObligatoria)="data"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('DataAccess.ConditionFilters.columns.isObligatory')"
                  rules="required"
                >
                  <b-form-checkbox
                    v-model="data.item.esObligatoria"
                    :value="true"
                    :unchecked-value="false"
                  />
                  <b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="danger"
                  @click="removeFilter(data.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle"
                  />
                </b-button>
              </template>
            </b-table>
          </div>

        </validation-observer>
        <b-alert
          v-if="elementData.objetoConsulta && elementData.objetoConsulta.enFuncionPaginacionPorParametro"
          show
          variant="info"
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50 mt-25"
            />
            No es posible agregar condiciones de filtrado a un objeto de consulta tipo funcion, que recibe parametros de paginación.
          </div>
        </b-alert>
      </tab-content>
      <!-- Columns Configuration -->
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardSteps.elementDetails')"
        :before-change="onValidationElementDetails"
      >
        <validation-observer
          ref="refElementDetailsRules"
          tag="form"
        >
          <!--Tabular-->
          <div
            v-if="elementData.tableroElementoTipoId === 1"
          >
            <b-row>
              <b-col
                cols="12"
                offset-md="1"
                md="10"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <strong class="mr-2">Filtrar</strong>
                <b-form-input
                  v-model="searchColumna"
                  placeholder="Nombre de columna"
                  debounce="300"
                />
              </b-col>
            </b-row>
            <b-row
              class="mb-1"
            >
              <b-col
                cols="12"
                md="4"
              >
                <strong>Columna</strong>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <strong>Etiqueta columna</strong>
              </b-col>
              <b-col
                v-if="searchColumna === ''"
                cols="12"
                md="2"
              >
                <b-form-checkbox
                  v-model="modalElementSelectAll"
                  @change="onModalElementSelectAll"
                >
                  <strong>Mostrar</strong>
                </b-form-checkbox>
              </b-col>
              <b-col
                v-else
                cols="12"
                md="2"
              >
                <strong>Mostrar</strong>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <strong
                  v-b-tooltip.hover
                  title="Arrastra y Suelta las filas para seleccionar el orden"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="align-middle"
                  />
                </strong>
              </b-col>
            </b-row>
            <vue-perfect-scrollbar
              v-if="searchColumna == ''"
              :settings="perfectScrollbarSettings"
              class="ps-customizer-area scroll-area"
            >
              <draggable
                v-model="elementData.tabularColumnas"
                class="list-group list-group-flush cursor-move"
                tag="ul"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <b-list-group-item
                    v-for="item in elementData.tabularColumnas"
                    :key="item.elementoTabularColumnaId"
                    tag="li"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        {{ item.objetoConsultaColumna.columna }}
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="$t('settings.dashboards.elements.addedit.conditionFilter.columns.label')"
                          rules="required"
                        >
                          <b-form-group>
                            <b-form-input
                              v-model="item.etiquetaMostrar"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <b-form-checkbox
                            v-model="item.mostrar"
                            @change="onTabularSelectColumn(item)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group>
                          <feather-icon
                            icon="ChevronsUpIcon"
                            size="16"
                            class="align-middle"
                          />
                          <feather-icon
                            icon="ChevronsDownIcon"
                            size="16"
                            class="align-middle"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </transition-group>
              </draggable>
            </vue-perfect-scrollbar>
            <b-list-group
              v-else
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="item in tabularColumnasSearch"
                :key="item.elementoTabularColumnaId"
                tag="li"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    {{ item.objetoConsultaColumna.columna }}
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('settings.dashboards.elements.addEdit.conditionFilter.columns.label')"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-input
                          v-model="item.etiquetaMostrar"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="item.mostrar"
                        @change="onTabularSelectColumn(item)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </div>
        </validation-observer>
      </tab-content>
      <tab-content
        :title="$t('settings.dashboards.elements.addedit.wizardAccess.title')"
      >
        <validation-observer
          ref="refQueryAddNewRules"
          tag="form"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.wizardAccess.selectType')"
                rules="required"
              >
                <b-form-group
                  :label="$t('settings.dashboards.elements.addedit.wizardAccess.selectType')"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="resetSelectOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    label="texto"
                    :reduce="option => option.textoAlterno"
                    :options="optionsRuleAccess"
                    @input="fetchSecondData"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="OpenSelectData"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.dashboards.elements.addedit.wizardAccess.selectType')"
                rules="required"
              >
                <b-form-group
                  :label="nameLabel"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="valueSecondSelect"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    label="name"
                    :reduce="option => option.id"
                    :options="responseDataFromType"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="OpenSelectData"
            >
              <b-form-group
                label="ㅤ"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="valueSecondSelect.length > 0 ? false : true"
                  @click="AddItemAccessRule"
                >
                  {{ $t('Lists.Add') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <list
              ref="refAccessRuleTable"
              sort-by="elementoReglaAccesoId"
              refetch-records-name="reglaAccesos"
              key-field="ElementoReglaAccesoId"
              :actions="actions"
              :filters.sync="filters"
              :table-columns="registerTableColumns"
              :table-actions="tableActions"
              :refetch-records="fecthRecord"
              :delete-method="deleteRulesAccessElement"
            />
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
    <b-modal
      id="add-query-object-modal"
      ref="refAddQueryObjectModal"
      size="lg"
      hide-footer
      :title="$t('DataAccess.QueryObjects.AddNew.newQueryObject')"
    >
      <query-object-add @refresh-data="refreshData" />
    </b-modal>
    <b-modal
      id="condition-filter-add"
      ref="refAddConditionFilter"
      size="lg"
      hide-footer
      :title="$t('settings.dashboards.elements.addedit.columns.addConditionFilter')"
    >
      <b-row>
        <b-col
          cols="12"
          offset-md="2"
          md="8"
        >
          <b-form-group
            class="mt-1"
            :label="$t('settings.dashboards.elements.addedit.columns.conditionFilter')"
          >
            <v-select
              v-model="newConditionFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="texto"
              :reduce="item => item.id"
              :options="conditionFilterOptions"
              transition=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="float-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="newConditionFilter == null"
          @click="addElementFilter"
        >
          {{ $t('Lists.Add') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import List from '@/components/List.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  VBTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BTable,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BButton,
  BModal,
  BAlert,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import dashboardService from '@/services/dashboard.service'
import optionService from '@/services/option.service'
import useradminService from '@/services/useradmin.service'
import generalService from '@/services/generalData.service'
import router from '@/router'
import i18n from '@/libs/i18n'
import QueryObjectAdd from '../QueryObjectAdd.vue'

export default {
  components: {
    List,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BButton,
    BModal,
    BAlert,
    draggable,
    vSelect,
    QueryObjectAdd,
    VuePerfectScrollbar,

    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const refAccessRuleTable = ref(null)
    let isNewElement = router.currentRoute.name === 'settings-dashboards-elements-new'
    const {
      fetchCustomers,
      fetchOptions,
      fecthCustoms,
      fetchPatents,
    } = optionService()
    const isActiveModal = ref(false)
    const {
      fetchElementById,
      createElement,
      updateElement,

      fetchElementQueryObject,
      updateElementQueryObject,
      fetchElementConditionFilters,
      createElementConditionFilter,
      updateElementConditionFilters,
      removeElementConditionFilter,
      fetchElementTabColumns,
      updateElementTabColumns,
      fetchRulesAccessUserElement,
      createRulesAccessElement,
      deleteRulesAccessElement,
    } = dashboardService()
    const { fetchConditionFilter } = generalService()
    const {
      fetchRoles,
      fetchAllUsers,
    } = useradminService()

    const elementData = ref({
      elementoId: null,
      tableroElementoTipoId: null,
      nombre: '',
      descripcion: null,
      objetoConsultaId: null,
      condicionesFiltrado: [],
      objetoConsultaColumnas: [{ objetoConsultaColumnaId: null }],
      tabularColumnas: [],
    })

    const resetSelectOptions = ref('')
    const OpenSelectData = ref('')
    const tabularColumnasSearch = ref([])
    const searchColumna = ref('')
    const parameters = ref([])
    const columnsParameters = ref([])
    const nameLabel = ref('')
    const responseDataFromType = ref([])

    if (!isNewElement) {
      fetchElementById({ elementoId: router.currentRoute.params.elementoId }, data => {
        const element = data
        element.objetoConsultaId = null
        element.condicionesFiltrado = []
        element.objetoConsultaColumnas = [{ objetoConsultaColumnaId: null }]
        element.tabularColumnas = []
        elementData.value = element
      })
      fetchElementConditionFilters({ elementoId: router.currentRoute.params.elementoId, archivado: false, parametro: true }, dataCf => {
        parameters.value = dataCf
      })
    }

    const refGeneralValidationRules = ref(null)
    const refQueryObjectValidationRules = ref(null)
    const refQueryAddNewRules = ref(null)
    const refParametersValidationRules = ref(null)
    const refConditionFilterValidationRules = ref(null)
    const refElementDetailsRules = ref(null)
    const refAddQueryObjectModal = ref(null)
    const refAddConditionFilter = ref(null)
    const refConditionFilterTable = ref(null)
    const refParametersTable = ref(null)
    const filters = ref([])

    const elementTypes = ref([])
    fetchOptions({
      nombreDominio: 'TableroElementoTipo',
    }, data => { elementTypes.value = data })
    const queryObjects = ref([])
    fetchOptions({
      nombreDominio: 'ObjetosConsulta',
    }, data => { queryObjects.value = data })
    const newConditionFilter = ref(null)
    const conditionFilterOptions = ref([])
    fetchOptions({
      nombreDominio: 'CondicionesFiltrado',
    }, data => { conditionFilterOptions.value = data })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const conditionFilterFields = [
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.name'),
        key: 'condicionFiltrado.etiqueta',
        sortable: false,
      },
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.label'),
        key: 'etiqueta',
        sortable: false,
      },
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.column'),
        key: 'objetoConsultaColumnaId',
        sortable: false,
      },
      {
        label: i18n.t('DataAccess.ConditionFilters.columns.isObligatory'),
        key: 'esObligatoria',
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]

    const parametersFields = [
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.name'),
        key: 'condicionFiltradoId',
        sortable: false,
      },

      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.label'),
        key: 'etiqueta',
        sortable: false,
      },
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.column'),
        key: 'objetoConsultaColumnaId',
        sortable: false,
      },
      {
        label: i18n.t('settings.dashboards.elements.addedit.conditionFilter.list.required'),
        key: 'esObligatoria',
        sortable: false,
      },
    ]

    const getElementQueryObject = () => {
      fetchElementQueryObject({ elementoId: elementData.value.elementoId }, dataQo => {
        elementData.value.objetoConsultaId = dataQo.objetoConsultaId
        elementData.value.objetoConsulta = dataQo
        elementData.value.tabularColumnas = []
      })
    }

    const getQueryObjectColumns = domainName => {
      fetchOptions({
        nombreDominio: domainName,
        llaveBuscar: elementData.value.objetoConsultaId,
      }, data => {
        if (domainName === 'ObjetoConsultaColumnas') {
          elementData.value.objetoConsultaColumnas = data
        } else {
          columnsParameters.value = data
        }
      })
    }
    // ReglasAcceso

    // Select de tipo de dominio
    const optionsRuleAccess = ref([
      { texto: i18n.t('settings.dashboards.elements.addedit.wizardAccess.userName'), textoAlterno: 'nombre_usuario' },
      { texto: i18n.t('settings.dashboards.elements.addedit.wizardAccess.rol'), textoAlterno: 'rol' },
      { texto: i18n.t('settings.dashboards.elements.addedit.wizardAccess.client'), textoAlterno: 'cliente' },
      { texto: i18n.t('settings.dashboards.elements.addedit.wizardAccess.customer'), textoAlterno: 'aduana' },
      { texto: i18n.t('settings.dashboards.elements.addedit.wizardAccess.patent'), textoAlterno: 'patente' },
    ])
    // Variable donde se almacenan los datos del segundo select
    const valueSecondSelect = ref([])
    // Funcion para cargar y mostrar registros del dominio seleccionado
    // en otro select
    const fetchSecondData = opc => {
      OpenSelectData.value = opc
      responseDataFromType.value = []
      valueSecondSelect.value = []
      switch (opc) {
        case 'nombre_usuario':
          nameLabel.value = i18n.t('settings.dashboards.elements.addedit.wizardAccess.usersName')
          fetchAllUsers(users => {
            responseDataFromType.value = users.map(user => ({
              id: user.userName,
              name: user.name,
            }))
          })
          break
        case 'rol':
          nameLabel.value = i18n.t('settings.dashboards.elements.addedit.wizardAccess.rols')
          fetchRoles(data => {
            responseDataFromType.value = data.map(rol => ({
              id: rol.name,
              name: rol.name,
            }))
          })
          break
        case 'cliente':
          nameLabel.value = i18n.t('settings.dashboards.elements.addedit.wizardAccess.clients')
          fetchCustomers(customers => {
            responseDataFromType.value = customers.map(customer => ({
              id: customer.id,
              name: customer.texto,
            }))
          })
          break
        case 'aduana':
          nameLabel.value = i18n.t('settings.dashboards.elements.addedit.wizardAccess.customers')
          fecthCustoms(customes => {
            responseDataFromType.value = customes.map(custom => ({
              id: custom.id,
              name: custom.texto,
            }))
          })
          break
        case 'patente':
          nameLabel.value = i18n.t('settings.dashboards.elements.addedit.wizardAccess.patents')
          fetchPatents(patents => {
            responseDataFromType.value = patents.map(patent => ({
              id: patent.id,
              name: patent.texto,
            }))
          })
          break
        default:
          break
      }
    }
    // Agregar nuevas reglas de acceso
    const AddItemAccessRule = () => {
      createRulesAccessElement({ elementoId: router.currentRoute.params.elementoId, tipo: OpenSelectData.value, valores: valueSecondSelect.value })
        .then(() => {
          refAccessRuleTable.value.refreshData()
          resetSelectOptions.value = null
          OpenSelectData.value = null
        })
    }
    // Acciones de la tabla
    const actions = ref([])
    // Acciones de cada registro de la tabla
    const tableActions = ref([
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Tableros',
        params: ['elementoReglaAccesoId'],
        icon: 'TrashIcon',
      },
    ])

    // Columnas de la tabla
    const registerTableColumns = [
      {
        key: 'elementoReglaAccesoId',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.id'),
        sortable: true,
      },
      {
        key: 'nombreUsuario',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.user'),
        sortable: false,
      },
      {
        key: 'rol',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.rol'),
        sortable: false,
      },
      {
        key: 'cliente.nombre',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.client'),
        sortable: false,
      },
      {
        key: 'aduana.nombre',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.customer'),
        sortable: false,
      },
      {
        key: 'patente.nombre',
        label: i18n.t('settings.dashboards.elements.addedit.wizardAccess.patent'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
        sortKey: 'Accion',
      },
    ]
    //  Registros de la tabla
    const fecthRecord = (data, callback) => {
      const DataWid = { ...data, ElementoReglaAccesoId: router.currentRoute.params.elementoId }
      fetchRulesAccessUserElement(DataWid, records => {
        callback(records)
      })
    }
    // Agregar Regla Acceso
    // Nombres de columnas
    const dataTableColumns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
      },
      {
        key: 'name',
        label: 'usuario',
        sortable: false,
      },
    ]
    // Variable de los filtros de busqueda
    const filtersAdd = ref([])

    const getConditionFilters = parameter => {
      fetchElementConditionFilters({ elementoId: elementData.value.elementoId, archivado: false, parametro: parameter }, data => {
        if (parameter) {
          parameters.value = data
          getQueryObjectColumns('ObjetoConsultaParametros')
        } else {
          elementData.value.condicionesFiltrado = data
          getQueryObjectColumns('ObjetoConsultaColumnas')
          refParametersTable.value.refresh()
        }
      })
    }

    const onValidationGeneral = () => new Promise((resolve, reject) => {
      refGeneralValidationRules.value.validate().then(success => {
        if (success) {
          if (isNewElement) {
            createElement(elementData.value, data => {
              elementData.value = data.elemento
              isNewElement = false
              getElementQueryObject()
            })
          } else {
            updateElement(elementData.value, () => {
              getElementQueryObject()
            })
          }
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const onValidationQueryObject = () => new Promise((resolve, reject) => {
      refQueryObjectValidationRules.value.validate().then(success => {
        if (success) {
          updateElementQueryObject({ elementoId: elementData.value.elementoId, objetoConsultaId: elementData.value.objetoConsultaId }, () => {
            getConditionFilters(true)
            if (parameters.value.length === 0) {
              getConditionFilters(false)
            }
          })
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const onValidationParameters = () => new Promise((resolve, reject) => {
      refParametersValidationRules.value.validate().then(success => {
        if (success) {
          updateElementConditionFilters({ elementoId: elementData.value.elementoId, condicionesFiltrado: parameters.value }, () => {
            getConditionFilters(false)
            resolve(true)
          })
        } else {
          reject()
        }
      })
    })

    const onValidationConditionFilter = () => new Promise((resolve, reject) => {
      refConditionFilterValidationRules.value.validate().then(success => {
        if (success) {
          fetchElementTabColumns({ elementoId: elementData.value.elementoId }, data => {
            searchColumna.value = 'd'
            elementData.value.tabularColumnas = data
            searchColumna.value = ''
            updateElementConditionFilters({ elementoId: elementData.value.elementoId, condicionesFiltrado: elementData.value.condicionesFiltrado }, () => {
              resolve(true)
            })
          })
        } else {
          reject()
        }
      })
    })

    const onValidationElementDetails = () => new Promise((resolve, reject) => {
      refElementDetailsRules.value.validate().then(success => {
        if (success) {
          updateElementTabColumns({ elementoId: elementData.value.elementoId, tabularColumnas: elementData.value.tabularColumnas }, () => {
            resolve(true)
          })
        } else {
          reject()
        }
      })
    })

    const onFormSubmitted = () => {
      router.push({ name: 'settings-dashboards-elements' })
    }

    const modalElementSelectAll = ref(false)

    const onModalElementSelectAll = () => {
      if (searchColumna.value === '') {
        elementData.value.tabularColumnas.forEach(column => {
          const asigColumn = column
          asigColumn.mostrar = modalElementSelectAll.value
        })
      }
    }

    const onTabularSelectColumn = item => {
      // eslint-disable-next-line no-param-reassign
      item.orden = item.mostrar ? -1 : item.orden
      elementData.value.tabularColumnas.sort((a, b) => a.orden - b.orden)
    }

    const removeFilter = conditionFilter => {
      removeElementConditionFilter({ elementoId: conditionFilter.elementoId, elementoCondicionFiltradoId: conditionFilter.elementoCondicionFiltradoId }, () => {
        const index = elementData.value.condicionesFiltrado.indexOf(conditionFilter)
        if (index !== -1) {
          elementData.value.condicionesFiltrado.splice(index, 1)
        }
      })
    }

    const addElementFilter = () => {
      fetchConditionFilter({ ConditionId: newConditionFilter.value }, conditionFilter => {
        createElementConditionFilter({ elementoId: elementData.value.elementoId }, conditionFilter, () => {
          fetchElementConditionFilters({ elementoId: elementData.value.elementoId, archivado: false }, dataCf => {
            elementData.value.condicionesFiltrado = dataCf
            newConditionFilter.value = null
            refAddConditionFilter.value.hide()
          })
        })
      })
    }

    const refreshData = () => {
      refAddQueryObjectModal.value.hide()
      fetchOptions({
        nombreDominio: 'ObjetosConsulta',
      }, data => { queryObjects.value = data })
    }

    watch(searchColumna, () => {
      if (searchColumna.value !== '') {
        tabularColumnasSearch.value = elementData.value.tabularColumnas.filter(columna => (columna.objetoConsultaColumna.columna.toUpperCase().includes(searchColumna.value.toUpperCase()) || columna.etiquetaMostrar.toUpperCase().includes(searchColumna.value.toUpperCase())))
      }
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      isNewElement,
      refGeneralValidationRules,
      refQueryObjectValidationRules,
      refParametersValidationRules,
      refConditionFilterValidationRules,
      refAddQueryObjectModal,
      refElementDetailsRules,
      refAddConditionFilter,
      refConditionFilterTable,
      refParametersTable,
      elementData,
      elementTypes,
      queryObjects,
      tabularColumnasSearch,
      searchColumna,
      conditionFilterOptions,
      conditionFilterFields,
      parametersFields,
      perfectScrollbarSettings,
      newConditionFilter,
      parameters,
      columnsParameters,

      FormWizard,
      TabContent,

      onFormSubmitted,
      onValidationGeneral,
      onValidationQueryObject,
      onValidationParameters,
      onValidationConditionFilter,
      onValidationElementDetails,

      modalElementSelectAll,
      onModalElementSelectAll,
      onTabularSelectColumn,
      removeFilter,
      refreshData,
      addElementFilter,

      optionsRuleAccess,
      OpenSelectData,
      fetchSecondData,
      nameLabel,
      responseDataFromType,
      registerTableColumns,
      actions,
      tableActions,
      filters,
      fecthRecord,
      isActiveModal,
      dataTableColumns,
      filtersAdd,
      AddItemAccessRule,
      valueSecondSelect,
      refQueryAddNewRules,
      refAccessRuleTable,
      deleteRulesAccessElement,
      resetSelectOptions,

      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.ps-customizer-area {
  height: 50vh
}
</style>
