<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        ref="refNewQueryObject"
      >
        <!--ConnectionString field--->
        <validation-provider
          #default="validationContext"
          name="cadenaConexionId"
          rules="required"
        >
          <b-form-group
            :label="$t('DataAccess.QueryObjects.columns.connectionString')"
          >
            <v-select
              v-model="queryObjectData.cadenaConexionId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="texto"
              :options="connectionStrings"
              :reduce="option => option.id"
              @input="refetchQueryObjectColumns(queryObjectData.cadenaConexionId)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Name field --->
        <validation-provider
          #default="validationContext"
          name="nombre"
          rules="required"
        >
          <b-form-group
            :label="$t('DataAccess.QueryObjects.columns.name')"
          >
            <b-form-input
              v-model="queryObjectData.nombre"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!--ObjetName field-->
        <validation-provider
          #default="validationContext"
          name="objetoNombre"
          rules="required"
        >
          <b-form-group
            :label="$t('DataAccess.QueryObjects.columns.objectName')"
          >
            <v-select
              v-model="queryObjectData.objetoConsulta"
              label="objetoConsultaNombreCompleto"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="queryObjectDatabase"
              transition=""
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Form Actions -->
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="isCreating"
          >
            {{ $t('Lists.Add') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
} from '@validations'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  setup(props, { emit }) {
    const {
      createQueryObject,
      fetchConnectionStringOptions,
      fetchQueryObjectDatabase,
    } = generalDataService()

    const blankQueryObjectData = {
      cadenaConexionId: null,
      objetoConsultaTipoId: null,
      nombre: null,
      descripcion: null,
      esquema: null,
      objetoNombre: null,
    }

    const isCreating = ref(false)
    const queryObjectData = ref(JSON.parse(JSON.stringify(blankQueryObjectData)))
    const resetClassificationData = () => {
      queryObjectData.value = JSON.parse(JSON.stringify(blankQueryObjectData))
    }
    const queryObjectDatabase = ref([])
    const refetchQueryObjectColumns = cadenaConexionId => {
      fetchQueryObjectDatabase(data => {
        const { objetosConsulta } = data
        queryObjectDatabase.value = objetosConsulta
      }, cadenaConexionId)
    }
    const connectionStrings = ref([])
    fetchConnectionStringOptions(data => {
      connectionStrings.value = data
    })
    /* Events */
    const onSelectQueryObject = () => {
      queryObjectData.value.esquema = queryObjectData.value.objetoConsulta.esquema
      queryObjectData.value.objetoConsultaTipoId = queryObjectData.value.objetoConsulta.objetoConsultaTipoId
      queryObjectData.value.objetoNombre = queryObjectData.value.objetoConsulta.objetoConsultaNombre
    }

    const refNewQueryObject = ref(null)
    const onSubmit = () => {
      onSelectQueryObject()
      isCreating.value = true
      createQueryObject(queryObjectData.value)
        .then(() => {
          isCreating.value = false
          refNewQueryObject.value.reset()
          emit('refresh-data')
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      queryObjectData,
      // queryObjectTypes,
      connectionStrings,
      queryObjectDatabase,
      // Events
      onSelectQueryObject,
      onSubmit,
      refetchQueryObjectColumns,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
      refNewQueryObject,
      isCreating,
    }
  },
}
</script>
